@import '../../../../scss/theme-bootstrap';

.product-content-card {
  display: inline-block;
  &__container {
    width: 100%;
    &.card-radius-10 {
      border-radius: 10px;
    }
    &.card-radius-15 {
      border-radius: 15px;
    }
    &.card-radius-20 {
      border-radius: 20px;
    }
    &.card-radius-25 {
      border-radius: 25px;
    }
    &.card-radius-30 {
      border-radius: 30px;
    }
  }
  &__content,
  &__product {
    margin: 0 auto;
    &:not([class*='padding-']) {
      padding: 15px 10px;
      @include breakpoint($landscape-up) {
        padding: 35px;
      }
    }
    &:not([class*='max-width-']) {
      max-width: 370px;
      @include breakpoint($landscape-up) {
        max-width: 390px;
      }
    }
    &.text-align--left {
      .product-content-card__link {
        a {
          margin-#{$ldirection}: 0;
        }
      }
      button {
        margin-#{$ldirection}: 0;
      }
      .product-content-card__product-partial {
        .product-content-card__links {
          .product-content-card-link,
          .product-content-card-overlay,
          .product-content-card-atb {
            margin-#{$ldirection}: 0;
          }
        }
      }
    }
    &.text-align--right {
      .product-content-card__link {
        a {
          margin-#{$rdirection}: 0;
        }
      }
      button {
        margin-#{$rdirection}: 0;
      }
      .product-content-card__product-partial {
        .product-content-card__links {
          .product-content-card-link,
          .product-content-card-overlay,
          .product-content-card-atb {
            margin-#{$rdirection}: 0;
          }
        }
      }
    }
  }
  &__overlay {
    margin: 20px auto 0;
    width: 100%;
    max-width: 280px;
    &.button {
      border: 1px solid $color-cream-stroke;
    }
  }
  &__media {
    display: inline-block;
  }
  &__copy {
    margin-top: 10px;
    line-height: 1.33;
    @include breakpoint($landscape-up) {
      line-height: 1.5;
    }
    &.product-content-card__copy-header {
      margin-top: 7px;
    }
    /* stylelint-disable value-no-vendor-prefix */
    &:not(.extra-product-content) {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      width: 90%;
      margin: 0 auto;
      overflow: hidden;
      height: 40px;
    }
    /* stylelint-enable value-no-vendor-prefix */
  }
  &__link {
    margin-top: 20px;
    a,
    button {
      width: 100%;
      max-width: 280px;
      margin: 0 auto;
      &.button {
        border: 1px solid $color-cream-stroke;
      }
    }
  }
  .product-content-card__product-partial {
    .product-content-card__links {
      margin-top: 20px;
      a,
      button {
        width: 100%;
        max-width: 280px;
        margin: 0 auto;
        &.button {
          border: 1px solid $color-cream-stroke;
        }
      }
      .product-content-card-link,
      .product-content-card-overlay {
        display: none;
        margin: 0 auto;
      }
      .product-content-card-atb {
        display: block;
        margin: 0 auto;
      }
    }
    .product-content-card__copy {
      &.extra-product-content {
        display: inline-block;
        margin: 7px 5px 0;
      }
    }
  }
  &[class*='max-width-'] {
    width: 100%;
  }
  &.padding-20 {
    padding-inline: 20px;
  }
}

.content-card-root {
  text-align: center;
  &:has(.card-alignment-text-align--left) {
    text-align: #{$ldirection};
  }
  &:has(.card-alignment-text-align--right) {
    text-align: #{$rdirection};
  }
}

.product-content-card__content:hover {
  cursor: pointer;
}
